<template>
<CRow>
  <CCol col="12" xl="12">
       <CCard class="cards">
      <CCardHeader>
        <CCol col="6" class="styleHeader">{{ $t('message.showExpense')}} </CCol>
      </CCardHeader>
      <CCardBody>
        <div class="row">
            <div class="col-md-6">
                <table class="table table-bordered">
                  <tr>
                    <th>{{ $t('message.expenseName')}} </th>
                    <td>{{ expense.expense_name }}</td>
                  </tr>
                  <tr >
                    <th> {{ $t('message.expensePrice')}}</th>
                    <td>{{ expense.expense_price |number }}</td>
                  </tr>
                   <tr>
                    <th>{{ $t('message.description')}} </th>
                    <td v-if="expense.description != 'undefined'">{{ expense.description }}</td>
                  </tr>
                </table>
            </div>
        </div>
        <CButton color="btn btn-primary" @click="goBack">{{ $t('message.back')}}</CButton>
      </CCardBody>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
export default {
  name: 'ShowExpense',
  data () {
    return {
      expense: []
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}expenses/${this.$route.params.id}`)
      .then((response) => {
        this.expense = response.data.data.expense
        console.log(response.data.data.expense)
      })
  },
  methods: {
    goBack () {
      this.servicesOpened ? this.$router.go(-1) : this.$router.push({ path: '/expenses' })
    }
  }
}
</script>
<style scoped>
  th {
    background-color: #643c4f;
    color: #f5f3f3;
  }
  .title
  {
    background-color: #9daec5;
    color: #f5f3f3;
    border-radius: 20%;
    padding: 7px;
  }
</style>
